//
// =Layout
// --------------------------------------------------


// jwsdw.min.css

/* purgecss ignore */
.jwsdw-pageWrapper { max-width: none; }

.landingpage-wrapper {
  > section {
    position: relative;
    line-height: 0;
  }

  .jws-grid-container-gutter:not(.jwsdw-lottery-mainform) {
    margin-bottom: 0;

    > [class*="jws-grid-"] {
      margin-bottom: 0;
    }
  }
}

picture {
  display: block;
  position: relative;
  line-height: 0;
}

figure { margin: 0; }

// .msdw-section-boxed-to-fullwidth {
//   position: relative;
//   left: 50%;
//   width: 100vw;
//   transform: translate(-50%, 0);
// }

.msdw-container {
  max-width: 1480px;
  margin-right: auto;
  margin-left: auto;
}

// .msdw-grid {
//   @media #{$md-up} {
//     display: flex;
//     justify-content: space-between;

//     // max-width for IE11
//     .msdw-grid-col-1 { flex-basis: 100%; max-width: 100%; }
//     .msdw-grid-col-2 { flex-basis: calc(50% - 15px); max-width: calc(50% - 15px); }
//     .msdw-grid-col-3 { flex-basis: calc(33.333% - 15px); max-width: calc(33.333% - 15px); }
//     .msdw-grid-col-4 { flex-basis: calc(25% - 15px); max-width: calc(25% - 15px); }
//   }

//   .msdw-grid-col { flex: 1; }
// }

// .msdw-grid-no-gutter {
//   @media #{$md-up} {
//     display: flex;

//     .msdw-grid-col-1 { flex-basis: 100%; max-width: 100%; }
//     .msdw-grid-col-2 { flex-basis: 50%; max-width: 50%; }
//     .msdw-grid-col-3 { flex-basis: 33.333%; max-width: 33.333%; }
//     .msdw-grid-col-4 { flex-basis: 25%; max-width: 25%; }
//   }
// }

// @media #{$md-up} {

//   // alignment for VERTICAL
//   .msdw-grid-middle,
//   .msdw-grid-bottom {
//     flex-direction: row;
//   }

//   .msdw-grid-middle { align-items: center; }
//   .msdw-grid-bottom { align-items: flex-end; }

//   // alignment for HORIZONTAL
//   .msdw-grid-center { justify-content: center; }
//   .msdw-grid-right { justify-content: flex-end; }

//   // order
//   .msdw-grid-col-first { order: -1; }
//   .msdw-grid-col-last { order: 1; }
// }
