
// Base
// Headvisual
// Intro text
// Footer


//
// =Base
// --------------------------------------------------

@import "partials/media-queries";
@import "partials/layout";
// @import "partials/animations";

// @import "partials/slides";
// @import "partials/lint";


//
// =Content Bar
// --------------------------------------------------

.jwsdw-stickyFooter-close {
  all: unset;

  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;

  padding: 14px;
  line-height: 0;

  @media #{$lg-up} {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    padding: 12px;
  }

  svg {
    width: 16px;
    height: 16px;
    pointer-events: none;

    // @media #{$lg-up} {
    //   width: 24px;
    //   height: 24px;
    // }
  }
}
